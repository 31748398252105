.verified {
  background-color: lightgreen;
}
.completed {
  background-color: green;
}
.failed {
  background-color: lightcoral;
}
.planned {
  background-color: lightgreen;
}
