body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes loading-busy {
  0% {
    border-color: #f76c6c;
  }
  50% {
    border-color: #f03030;
  }
  100% {
    border-color: #f76c6c;
  }
}

@keyframes loading-available {
  0% {
    border-color: #7cf09f;
  }
  50% {
    border-color: #13db4f;
  }
  100% {
    border-color: #7cf09f;
  }
}
