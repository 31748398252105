.zone-tooltip {
  background: none;
  border: none;
  box-shadow: none;
  color: black; /* Use a darker color for better readability */
  font-weight: bold;
  font-size: 16px; /* Increase the font size */
  text-shadow: 1px 1px 2px white; /* Add a text shadow for better contrast */
  pointer-events: none; /* Ensure it doesn't interfere with map interactions */
}
